import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Information = makeShortcode("Information");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "recording-start-conditions"
    }}>{`Recording start conditions`}</h1>
    <p>{`Using `}<a parentName="p" {...{
        "href": "/javascript-api/methods/#newpageview"
      }}><inlineCode parentName="a">{`newPageView`}</inlineCode></a>{` method you can specify your own conditions as a trigger for a recording. `}</p>
    <h3 {...{
      "id": "example-condition"
    }}>{`Example condition`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    {type: "event", name: "MouseClick", operator: "contain", key:"path", value: ".add-cart"}
`}</code></pre>
    <p>{`This condition will be fulfilled when user click on element where "`}<strong parentName="p">{`path`}</strong>{`" contain "`}<strong parentName="p">{`.add-cart`}</strong>{`" value.`}</p>
    <h3 {...{
      "id": "example-use"
    }}>{`Example use`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("newPageView", {conditions: [
  {type: "event", name: "MouseClick", operator: "contain", key:"path", value: ".add-cart"},
]});
`}</code></pre>
    <h3 {...{
      "id": "types"
    }}>{`Types`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use to specify condition based on user interaction (event).`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "operator-types"
    }}>{`Operator types`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Condition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`start`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`starts from`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base.indexOf(value) == 0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contain`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`contain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base.indexOf(value) > -1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`end`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ends with`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base.indexOf(value) == base.length - value.length`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`eq`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equal to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base == value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`neq`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`not equal to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base !== value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`greater than`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base > value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gte`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`greater than or equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base >= value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`lower than`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base < value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lte`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`lower than or equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`base <= value`}</td>
        </tr>
      </tbody>
    </table>
    <Information color="warning" mdxType="Information">
  Operators' condtions are case sensitive.
    </Information>
    <h2 {...{
      "id": "event"
    }}>{`Event`}</h2>
    <h3 {...{
      "id": "types-1"
    }}>{`Types`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MouseClick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mouse click`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`RageClick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`rage click`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ErrorClick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`error click`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Scroll`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`scoll inside any element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`WindowScroll`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`scoll main window`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MouseMove`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mouse move`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TouchMove`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mobile touch move`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "keys"
    }}>{`Keys`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`path`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Full DOM path to element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`body > #header > .link.add-cart[href="/cart"]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`el`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Element identificator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.link.add-cart[href="/cart"]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`txt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text content of element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add to cart`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "default-conditions"
    }}>{`Default conditions`}</h3>
    <p>{`If conditions are not specified, by default `}<a parentName="p" {...{
        "href": "/javascript-api/methods/#newpageview"
      }}><inlineCode parentName="a">{`newPageView`}</inlineCode></a>{` use conditions below for starting new `}<inlineCode parentName="p">{`pageView`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    [{ type: "event", name: ["MouseMove", "TouchMove", "Scroll", "WindowScroll"] }]
`}</code></pre>
    <p>{`Recording starts when one of events (`}<strong parentName="p">{`MouseMove`}</strong>{`, `}<strong parentName="p">{`TouchMove`}</strong>{`, `}<strong parentName="p">{`Scroll`}</strong>{`, `}<strong parentName="p">{`WindowScroll`}</strong>{`) occurs. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      